// import React, { lazy } from "react"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"
import Author from "../components/author"
import Oceanit from "../components/oceanit"
import NoteFromPat from "../components/note_from_pat"
import Preorder from "../components/preorder"
import PressLinks from "../components/press_links"
import GridLinks from "../components/grid_links"
import '../components/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';

function IndexPage() {

    return(
      <Layout>
        {/* <FullWidth/> */}
        <SEO title="Home" />
        <Intro/>

        <div className="container">
            <div className="row ">
                <div className="col-sm-12 col-md-8 offset-sm-0 offset-md-2" >
                  <Preorder/>
                  <NoteFromPat/>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row ">
                <div className="col-sm-12 col-md-12 offset-sm-0 " >
                  <PressLinks/>
                </div>
            </div>
        </div>        

        <div className="container">
            <div className="row ">
                <div className="col-sm-12 col-md-8 offset-sm-0 offset-md-2" >
                  <Author/>
                  
                </div>
            </div>
        </div>


        <div className="container">
            <div className="row ">
                <div className="col-sm-12 col-md-12 offset-sm-0 " >
                  <GridLinks/>
                </div>
            </div>
        </div>


        <div className="container">
            <div className="row ">
                <div className="col-sm-12 col-md-8 offset-sm-0 offset-md-2" >
                  
                  <Oceanit/>
                </div>
            </div>
        </div>

      </Layout>
    )
}


export default IndexPage

import React from "react";
import styled from 'styled-components'
import amazonIcon from "../images/icon-amazon.svg"
import appleBooksIcon from "../images/icon-apple-books.svg"
import bnIcon from "../images/icon-bn.svg"


const StoreIcon = styled.img`
  height: 125px;
`
const Container = styled.div`
  margin-top: 6rem;
  h1 {
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    color: #525f7f;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 2rem;

  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

`

const ShopLink = styled.div`
  background: #FFF;
  text-decoration: none !important;
  border-radius: 3px;
  border: none;
  padding: 0;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #404452;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  /*margin-left: 1rem;*/
/*  :hover{
    background-color: #7bed8b;
    transform: translateY(-1px);
    cursor: pointer;
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    transition: all .10s ease;
  }*/
`


const BookShopButton = styled.a`
  width: 30%;
  /*border: solid 1px gray;*/
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: solid 1px #fff;
  text-decoration: none !important;


  :hover{
    /*background-color: #7bed8b;*/
    border: solid 1px #BAC0C7;
    transform: translateY(-1px);
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 6.3px 12.5px -2.5px rgba(50,50,93,.25), 0 3.8px 7.5px -3.7px rgba(0,0,0,.3);
    transition: all .1s ease;
    a{
      text-decoration: none !important;
    }
  }
`

const Preorder = () => (
  <>
  <Container>
    <h1>Now Available on These Platforms:</h1>
    <ButtonContainer>
      <BookShopButton href="https://amzn.to/2A9Uffr">
        <StoreIcon src={amazonIcon}/>
        <ShopLink>Amazon</ShopLink>
      </BookShopButton>
      <BookShopButton href="https://apple.co/3cONooS">
        <StoreIcon src={appleBooksIcon}/>
        <ShopLink>Apple Books</ShopLink>
      </BookShopButton>
      <BookShopButton href="https://bit.ly/37fdrEo">
        <StoreIcon src={bnIcon}/>
        <ShopLink>Barnes & Noble</ShopLink>
      </BookShopButton>
    </ButtonContainer>
  </Container>
  </>
)

const StyledPreorder = styled(Preorder)`
  margin-top: 6rem;
`
// 
// const StyledOceanit = styled(Oceanit)`
//   margin-top: 6rem;
//   margin-bottom: 6rem;
//   /*margin-right: 30px;*/
// 
//   hr {
//     border: none;
//     height: 1px;
//     color: #E8E8E8;
//     background-color: #E8E8E8;
//   }
// 
//   p {
//     color: "#525f7f";
//     font-family: 'Open Sans', sans-serif;
//     font-size: 19px;
//     font-weight: 400;
//   }
// 

// 
//   h2 {
//     font-size: 22px;
//     font-family: 'Open Sans', sans-serif;
//     color: #525f7f;
//     line-height: 32px;
//     font-weight: 600;
//   }
// `

export default StyledPreorder
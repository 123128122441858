import React from "react";
import styled from 'styled-components'
import OceanitHq from "../images/oceanit-honolulu.jpg"


const Oceanit = ({ className, children}) => (
  <div className={className}>
    <h2>About Oceanit</h2>
    
      <p>
        Oceanit practices Intellectual Anarchy to blend interdisciplinary science, technology, engineering, and innovative thinking to benefit our global community. Through spin-outs, co-development partnerships, licensing, and direct manufacturing, Oceanit is able to deliver deep science to disruptive, real-world breakthroughs with a practice called “Mind-to-Market.”
      </p>


      <p>
        With headquarters in Honolulu, Oceanit employs over 160 scientists & engineers in Hawai’i, California, Texas, and Washington, D.C. We encourage inquisitiveness and are committed to pursuing responsible
        solutions to the world’s most difficult problems. Our culture values diverse teams with an elusive blend of people, expertise, technologies, and approaches to address and tackle problems like no one else. Across Oceanit, you will find uncanny teams of experts like nanotechnologists, structural engineers, sustainability experts, and biochemists working alongside each other. No two teams at Oceanit are the same.
      </p>

      <StyledImg src={OceanitHq} alt="" width="100%"/>
      <Caption>Oceanit Headquarters, Honolulu Hawai'i</Caption>

      <p>
        Empowering our teams to practice transdisciplinary thinking allows for extraordinary problem solving across traditional boundaries. Originally founded as an engineering firm, Oceanit has grown to encompass scientists and engineers working in the fields of nanomaterials, sensors & communications, life sciences & medicine, industrial products, artificial intelligence, energy, coastal sustainability, and space & defense.
      </p>

      

  </div>
)

const StyledImg = styled.img`
  /*margin-bottom: 60px;*/
  width: 100%;
  box-shadow: 0 6.3px 12.5px -2.5px rgba(50,50,93,.25), 0 3.8px 7.5px -3.7px rgba(0,0,0,.3);
`

const Caption = styled.div`
    color: "#525f7f";
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 3rem;
`

const StyledOceanit = styled(Oceanit)`
  margin-right: 30px;
  margin-top: 6rem;
  
  hr {
    border: none;
    height: 1px;
    color: #E8E8E8;
    background-color: #E8E8E8;
  }



  p {
    color: "#525f7f";
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-weight: 400;
  }

  h1 {
    color: #32325d;
    margin-bottom: .5rem;
    font-weight: 500;
  }

  h2 {
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    color: #525f7f;
    line-height: 32px;
    font-weight: 600;
  }
`

export default StyledOceanit
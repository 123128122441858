import React from "react";
import styled from 'styled-components'
import PatSullivan from "../images/pat_sullivan.jpg"


const StyledImg = styled.img`
  margin-bottom: 0;
  width: 100%;
  box-shadow: 0 6.3px 12.5px -2.5px rgba(50,50,93,.25), 0 3.8px 7.5px -3.7px rgba(0,0,0,.3);
`

const Author = ({ className, children}) => (
  <div className={className}>
    
    <TextWrapped>

      <ImageContainer>
        <StyledImg src={PatSullivan} alt=""/>
        <h2>Dr. Patrick Sullivan, CEO Oceanit</h2>
      </ImageContainer>
        <p>
          Patrick Sullivan is a scientist, engineer, technologist, entrepreneur, businessman, public speaker, and futurist. He founded Oceanit in 1985 and developed its "Intellectual Anarchy" approach to the art of disruptive innovation. Dr. Sullivan has launched numerous private equity financed start-up and spin-out companies. In 2016, Hawaii Business Magazine named him "CEO of the Year," and Hawaii Business News named him a "2019 Titan of Technology."
        </p>
        <p>
          Patrick Sullivan lives in Hawaii. He and his family are avid outdoorspeople and enjoy everything from surfing and skiing to rock climbing, as well as traveling to remote, uncharted places around the world with little more than a backpack.
        </p>
      </TextWrapped>

  </div>
)

const TextWrapped= styled.div`
  
`

const ImageContainer = styled.div`
  width: 40%;
  margin-right: 30px;
  float: left;
`

const StyledAuthor = styled(Author)`
 
  padding: 6rem 0;
  background-color: #404452;
  position: relative;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 9600px;
    right: 100%;
    background-color: #404452;
  }

  :after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 9600px;
    left: 100%;
    background-color: #404452;
    overflow-x: hidden;
  }

  hr {
    border: none;
    height: 1px;
    color: #E8E8E8;
    background-color: #E8E8E8;
  }

  p {
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-weight: 400;
  }

  h1 {
    /*color: #32325d;*/
    color: #68de79;
    margin-bottom: .5rem;
    font-weight: 500;
  }

  h2 {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    /*color: #525f7f;*/
    color: #68DE79;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 0;
  }
`

export default StyledAuthor
import React from "react";
import styled from 'styled-components'


const Oceanit = ({ className, children}) => (
  <div className={className}>
    
    <h2>What It’s All About</h2>
      <p>
        <i>Intellectual Anarchy</i> is about how we can find disruptive solutions to hard problems, problems so difficult that they can seem impossible, such as climate change, sustainable energy, and rising healthcare costs. Problems of that magnitude demand radical new ideas—an incremental approach won’t work. Their solutions seemingly come out of left field, defying conventional wisdom, and overturning existing industries, practices, and business models
      </p>
      <p>
        This book details the Intellectual Anarchy methodology followed at Oceanit for generating disruptive solutions to these problems and taking them from the idea stage all the way to the market—"Mind-to-Market”—including financing innovations to overcome the scarcity of venture capital anywhere away from the conventional tech hubs.
      </p>
  </div>
)

const StyledOceanit = styled(Oceanit)`
  margin-top: 6rem;
  margin-bottom: 6rem;
  /*margin-right: 30px;*/

  hr {
    border: none;
    height: 1px;
    color: #E8E8E8;
    background-color: #E8E8E8;
  }

  p {
    color: "#525f7f";
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-weight: 400;
  }

  h1 {
    color: #32325d;
    margin-bottom: .5rem;
    font-weight: 500;
  }

  h2 {
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    color: #525f7f;
    line-height: 32px;
    font-weight: 600;
  }
`

export default StyledOceanit
import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const GridLinks = ({ children}) => {
  const pages = useStaticQuery(graphql`
    {
      allPrismicArticle {
        edges {
          node {
            id
            uid
            data {
              title
              subtitle {
                text
                html
              }
              link_caption{
                text
              }
              link_background {
                url
              }
            }
          }
        }
      }
    }
  `)

  const links = pages.allPrismicArticle.edges.map((item, key) => {
      const data = item.node.data
        return(
          
            <LinkItem href={"/" + item.node.uid}>
              <LinkItemTop>
                <Title>{data.title}</Title>
                <Background  bg= {data.link_background.url} className="imageBg"/>
              </LinkItemTop>
              <LinkItemBottom>
                {data.link_caption.text}
              </LinkItemBottom>
            </LinkItem>
          
          )
        })

  return  (
    <FullWidthSection>
      <TitleContainer>
        <h2>Mind to Market</h2>
      </TitleContainer>
      <GridContainer>
        {/* <h2>Mind to Market</h2> */}
        {links}

      </GridContainer>
    </FullWidthSection>
  )
}

const GridContainer= styled.div`
  height: 100%;
  width: 100%;
  /*border: dashed 1px red;*/
  justify-content: space-between;
  display: flex;
  @media (max-width: 768px){
    flex-direction: column;
  }
`

const LinkItem = styled.a`
  /*width: 25%;*/
  flex: 0 0 calc(calc(100% / 3) - 15px);
  overflow: hidden;
  border-radius: 8px;
  border: solid 1px #BAC0C7;
  transition: all .5s ease-in-out;
  @media (max-width: 768px){
    margin-bottom: 4rem;
  }

  .imageBg {
    transition: all .5s ease-in-out;
  }
  &:hover{
    text-decoration: none;
    transform: scale(1.05);
    box-shadow: 0 6.3px 12.5px -2.5px rgba(50,50,93,.25), 0 3.8px 7.5px -3.7px rgba(0,0,0,.3);
    /*transition: box-shadow 0.5s ease-in-out;*/

    .imageBg {
      transform: scale(1.2);
    }
  } 
`

const Title = styled.div`
  z-index: 1
`

const Background = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  /*background-color: blue;*/
  background-image: url(${props => props.bg});
  background-size: cover;
  z-index: 0;
`

const LinkItemTop = styled.div`
  height: 200px;
  background-color: #404452;
  background-image: url(${props => props.bg});
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 32px;
  font-weight: 600;
  color: #7EE68F;
  
  position: relative;
  /*z-index: 0 !important;*/
`

const LinkItemBottom = styled.div`
  /*border: solid 1px #BAC0C7;*/
  background-color: white;
  border-radius: 0 0 8px 8px;
  border-top: none;
  padding: 1.25rem;
  font-size: 19px;
  line-height: 26px;
  font-weight: 400;
  color: #212529;
  /*z-index: 99 !important;*/
  position: relative;
`

const TitleContainer = styled.div`
  height: 6rem;
  display: flex;
  align-items: center; 
  justify-content: center;
`

const FullWidthSection = styled.div`
  /*border: solid 1px red;*/
  padding: 6rem 0;
  padding-top: 0;
  background-color: #F6F9FC;
  position: relative;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 9600px;
    right: 100%;
    background-color: #F6F9FC;
    z-index: -1;
  }

  :after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 9600px;
    left: 100%;
    background-color: #F6F9FC;
    overflow-x: hidden;
    z-index: -1;
  }

  hr {
    border: none;
    height: 1px;
    color: #E8E8E8;
    background-color: #E8E8E8;
  }

  p {
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-weight: 400;
  }

  h1 {
    /*color: #32325d;*/
    color: #68de79;
    margin-bottom: .5rem;
    font-weight: 500;
  }

  h2 {
    font-size: 22px;
    font-family: 'Open Sans',sans-serif;
    color: #525f7f;
    line-height: 32px;
    font-weight: 600;
    margin: 0;
  }
`

export default GridLinks